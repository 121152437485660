import { ethers } from 'ethers';
import { useEffect, useState } from "react";
const Mint = (): JSX.Element => {
  const [account, setAccount] = useState<any>(null)
  const [chainId, setChainId] = useState<any>(null)


  useEffect(() => {
    //@ts-ignore
    window.okxwallet.on('accountsChanged', (accounts) => {
      setAccount(accounts[0]);
    });
    //@ts-ignore
    window.okxwallet.on('chainChanged', (chainId) => {
      setChainId(chainId);
    });
  }, []);

  const connect = () => {
    //@ts-ignore
    window.okxwallet
      .request({ method: "eth_requestAccounts" })
      .then((res: any) => {
        console.log('%c🀃 res', 'color: #8c0038; font-size: 20px;', res);
        setAccount(res[0]);
        switchToCustomNetwork();
      })
      .catch((err: { code: number }) => {
        if (err.code === 4001) {
          console.log("Please connect to OKX Wallet.");
        } else {
          console.error(err);
        }
      });
  };

  useEffect(() => {
    connect();
  });

  const switchToCustomNetwork = async () => {
    //@ts-ignore
    const okxwallet = window.okxwallet;
    try {
      await okxwallet.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1b59' }],
      });
      setChainId(okxwallet.chainId);
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        try {
          await okxwallet.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x1b59',
                chainName: 'ZetaChain Testnet',
                rpcUrls: ['https://zetachain-athens-evm.blockpi.network/v1/rpc/public'],
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
    }
  };






  const mintFun = async () => {

    //@ts-ignore
    const provider = new ethers.providers.Web3Provider(window.okxwallet);
    //@ts-ignore
    const accounts = await window.okxwallet.enable(); // 启用钱包
    const signer = provider.getSigner();


    const contractAddress = '0x2A7DAF40292cbEe2E21c3701D786152e5094Da7E';
    const ABI = [
      'function mint(address,string) external',
    ];
    const contract = new ethers.Contract(contractAddress, ABI, signer);
    const tx = await contract.mint(accounts[0], 'zetachain.com');
    console.log('%c🀁 tx', 'color: #d0bfff; font-size: 20px;', tx);


  }

  return (
    <>
      <button onClick={() => {
        connect()
      }}>连接钱包</button>
      <button onClick={switchToCustomNetwork}>切换网络</button>
      <button onClick={mintFun}>mint</button>
      <p>{account}</p>
      <p>{chainId}</p>
    </>
  );
}

export default Mint;