import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { ethers } from "ethers";
import gsap from "gsap";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { isMobile } from "../../libs/userAgent";
// import { toast } from "react-toastify";
import './index.scss'




const Home = (): JSX.Element => {


  const [account, setAccount] = useState(null)
  const [, setChainId] = useState<any>(null)

  const [selectWalletType, setSelectWallectType] = useState<any>(null)

  const [innerHeight, setInnerHeight] = useState(0)

  const OkxWalletConnect = () => {

    setSelectWallectType('okx')
    return new Promise((resolve, reject) => {
      //@ts-ignore
      window.okxwallet
        .request({ method: "eth_requestAccounts" })
        .then((res: any) => {
          setAccount(res[0]);
          switchOKXToCustomNetwork()
          closeWalletModel()
          resolve(false); // Resolve the promise when the connection is successful
        })
        .catch((err: { code: number }) => {
          if (err.code === 4001) {
            console.log("Please connect to OKX Wallet.");
          } else {
            console.error(err);
          }
        });
    });
  };

  const TPWalletConnect = () => {
    const params: any = {
      callbackUrl: "https://characterx-galxe.pages.dev/",
      action: "login",
      actionId: "1648522106711",
      blockchains: [{
        chainId: "7001",
        network: "zetachain testnetwork"
      }],
      dappIcon: "https://characterx-galxe.pages.dev/favicon.ico",
      dappName: "characterx",
      protocol: "TokenPocket",
      version: "2.0"
    }

    console.log('%c🀀 ', 'color: #e50000; font-size: 20px;', params);
    const paramString = JSON.stringify(params);
    const encodedParam: string = encodeURIComponent(paramString);
    console.log('%c🀃 encodedParam', 'color: #aa00ff; font-size: 20px;', encodedParam);
    if (isMobile()) {
      window.open(`tpoutside://pull.activity?param=${encodedParam}`)
      return
    }

    setSelectWallectType('tp')
    return new Promise((resolve, reject) => {
      //@ts-ignore
      window.tokenpocket.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res: any) => {
          console.log('%c🀂 res', 'color: #00e600; font-size: 20px;', res);
          setAccount(res[0]);
          switchTPToCustomNetwork()
          resolve(false); // Resolve the promise when the connection is successful
        })
        .catch((err: { code: number }) => {
          if (err.code === 4001) {
            console.log("Please connect to OKX Wallet.");
          } else {
            console.error(err);
          }
        });
    });
  };

  useEffect(() => {
    console.log(window.innerHeight)
    setInnerHeight(window.innerHeight)
  }, [])






  const [infoData, setInfoData] = useState({
    avatar: '',
    name: '',
    definition: ''
  })

  useEffect(() => {
    const storedData = localStorage.getItem('infoData');
    const StoreStepOrd = localStorage.getItem('stepOrd');
    if (storedData) {
      setInfoData(JSON.parse(storedData))
    }
    stepChangFun(1)
    if (StoreStepOrd) {
      setStepOrd(Number(StoreStepOrd))
      stepChangFun(Number(StoreStepOrd))
    } else {
      stepChangFun(1)
    }
  }, [])


  const handleFileChange = (e: { target: { files: any[]; }; }) => {
    const MAX_FILE_SIZE = 3 * 1024; // 2MB in bytes
    const file = e.target.files[0];
    if (file) {
      if (file.size / 1024 > MAX_FILE_SIZE) {
        // 文件超出大小限制，这里可以添加一些处理逻辑
        toast.warn('The maximum upload size is 3M. Your file size has been exceeded.');
        return false; // 结束函数执行
      }

      // 在这里处理选中的文件，比如上传或显示预览图
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const base64String = event.target.result;

        // 保存至本地存储
        localStorage.setItem('uploadedImage', base64String);

        setInfoData({
          ...infoData,
          avatar: base64String
        })
        localStorage.setItem('infoData', JSON.stringify({
          ...infoData,
          avatar: base64String
        }));
        closeCameartModel()
        console.log('Image saved to local storage');
      };

      // 将文件转换为 base64
      reader.readAsDataURL(file);
    }
  };
  const fileInputRef = useRef<any>(null)
  const fileInputRef2 = useRef<any>(null)

  const showCamearModel = () => {
    gsap.to('.camear_model', { top: 0, duration: .5, ease: 'circ.out' })
  }
  const closeCameartModel = () => {
    gsap.to('.camear_model', { top: '100%', duration: .5, ease: 'circ.out' })
  }

  const [, setStepOrd] = useState(1)

  const stepChangFun = (ord: any) => {
    gsap.to(`.step${ord}`, { top: 0, duration: .5, ease: 'none' })
  }


  const showWalletModel = () => {
    gsap.to('.wallet_model', { top: 0, zIndex: 999999, duration: .5, ease: 'circ.out' })
  }
  const closeWalletModel = () => {
    gsap.to('.wallet_model', { top: '100%', zIndex: -999999, duration: .5, ease: 'circ.out' })
  }

  useEffect(() => {
    showWalletModel()
  }, [])

  const mintTPFun = async () => {
    setLiading(true)
    //@ts-ignore
    const provider = new ethers.providers.Web3Provider(window.tokenpocket.ethereum);
    //@ts-ignore
    const accounts = await window.tokenpocket.ethereum.enable(); // 启用钱包
    console.log('%c🀀 accounts', 'color: #00a3cc; font-size: 20px;', accounts);
    const signer = provider.getSigner();


    const contractAddress = '0x2A7DAF40292cbEe2E21c3701D786152e5094Da7E';
    const ABI = [
      'function mint(address,string) external',
    ];
    try {
      const contract = new ethers.Contract(contractAddress, ABI, signer);
      const tx = await contract.mint(accounts[0], 'zetachain.com');
      console.log('%c🀅 tx', 'color: #cc0036; font-size: 20px;', tx);
      stepChangFun(5)
      // 保存至本地存储
      localStorage.setItem('stepOrd', '5');
    } catch (error) {
      console.log('%c🀅 error', 'color: #607339; font-size: 20px;', error);

    }



    setLiading(false)
  }

  const mintOKXFun = async () => {
    setLiading(true)
    //@ts-ignore
    const provider = new ethers.providers.Web3Provider(window.okxwallet);
    //@ts-ignore
    const accounts = await window.okxwallet.enable(); // 启用钱包
    const signer = provider.getSigner();


    const contractAddress = '0x2A7DAF40292cbEe2E21c3701D786152e5094Da7E';
    const ABI = [
      'function mint(address,string) external',
    ];
    try {
      const contract = new ethers.Contract(contractAddress, ABI, signer);
      const tx = await contract.mint(accounts[0], 'zetachain.com');
      console.log('%c🀅 tx', 'color: #cc0036; font-size: 20px;', tx);
      stepChangFun(5)
      // 保存至本地存储
      localStorage.setItem('stepOrd', '5');
    } catch (error) {
      console.log('%c🀅 error', 'color: #607339; font-size: 20px;', error);

    }



    setLiading(false)
  }
  const [loading, setLiading] = useState(false)


  const switchOKXToCustomNetwork = async () => {
    //@ts-ignore
    const okxwallet = window.okxwallet;
    try {
      await okxwallet.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1b59' }],
      });
      setChainId(okxwallet.chainId);
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        try {
          await okxwallet.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x1b59',
                chainName: 'ZetaChain Testnet',
                rpcUrls: ['https://zetachain-athens-evm.blockpi.network/v1/rpc/public'],
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
    }
    closeWalletModel()
  };


  const switchTPToCustomNetwork = async () => {
    //@ts-ignore
    const TPwallet = window.tokenpocket.ethereum;
    try {
      await TPwallet.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1b59' }],
      });
      setChainId(TPwallet.chainId);
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        try {
          await TPwallet.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x1b59',
                chainName: 'ZetaChain Testnet',
                rpcUrls: ['https://zetachain-athens-evm.blockpi.network/v1/rpc/public'],
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
    }
    closeWalletModel()
  };


  const handleTwitterAuth = async () => {
    // Send a request to your backend to initiate Twitter OAuth process
    setLiading(true)
    await axios.get('https://sandbox-api.characterx.ai/gptx/v1/twitter-auth')
      .then((response: any) => {
        console.log(response.data); // 处理返回的数据
        setTimeout(() => {
          window.location.href = response.data.redirectUrl; // Assuming backend sends the 
        }, 500)

        // redirect URL
        // stepChangFun(4)
        // 保存至本地存储
        localStorage.setItem('stepOrd', '4')

      })
      .catch((error: any) => {
        toast.error(error.message);
      });

    setLiading(false)

  };


  return (
    <>
      <div className="home"
        style={{
          height: innerHeight
        }}
      >
        <div className="header">
          <div className="logo">
            <img height={'100%'} src={require('../../assets/image/logo.png')} alt="logo" />
          </div>
          <div className="wallet">
            <button onClick={() => {
              if (!account) {
                showWalletModel()
              }
            }}>
              <img src={require('../../assets/image/wallet.png')} alt="" />
            </button>
          </div>
        </div>
        <div className="main">
          <div className="step1 step" >
            <div className="banner">
              <img src={require('../../assets/image/banner.png')} alt="" width={'100%'} />
            </div>
            <div className="game">
              <img className="cloud1" src={require('../../assets/image/cloud-1.png')} alt="" />
              <img className="cloud2" src={require('../../assets/image/cloud-2.png')} alt="" />
              <img className="cloud3" src={require('../../assets/image/cloud-3.png')} alt="" />
              <img className="sun" src={require('../../assets/image/sun.png')} alt="" />
              <img className="shu-left" src={require('../../assets/image/shu-left.png')} alt="" />
              <img className="shu-right" src={require('../../assets/image/shu-right.png')} alt="" />
              {/* <img className="game-text" src={require('../../assets/image/game-text.png')} alt="" /> */}
              <h1 className="game-text">
                Create your AI friend! <br />
                Get Early Member Pass!
              </h1>
              <button className="Create"
                onClick={() => {
                  if (!account) {
                    showWalletModel()

                  } else {
                    stepChangFun(2)
                    // 保存至本地存储
                    localStorage.setItem('stepOrd', '2');
                  }

                }}
              >
                <img src={require('../../assets/image/add.png')} alt="" /> Create My Character
              </button>
            </div>

          </div >
          <div className="step2 step">
            <div className="from">
              <div className="avatar">
                <div className="file"
                  onClick={showCamearModel}
                >
                  <div className="avatar-img">

                    {
                      !infoData.avatar
                        ? <img src={require('../../assets/image/default-avatar.png')} alt="" width={'100%'} />

                        : <img src={infoData.avatar} alt="" width={'100%'} />
                    }
                  </div>
                  <div className="camera">
                    <img src={require('../../assets/image/Camera.png')} alt="" width={'100%'} />
                  </div>
                </div>
              </div>
              <div className="name">
                <label><span>*</span> Name</label>
                <div className="from-box">
                  <input
                    type="text"
                    value={infoData.name}
                    onChange={(e) => {
                      const { value } = e.target;

                      // 如果输入长度超过 10，则阻止继续输入
                      if (value.length <= 20) {
                        setInfoData({
                          ...infoData,
                          name: value
                        })
                      }
                    }}
                    placeholder="Enter the name"
                  />
                  <div className="count">
                    {infoData.name.length}/20
                  </div>
                </div>
              </div>
              <div className="definition">
                <label><span>*</span> Definition (Introduction)</label>
                <div className="from-box">
                  <textarea
                    value={infoData.definition}
                    onChange={(e) => {
                      const { value } = e.target;

                      // 如果输入长度超过 10，则阻止继续输入
                      if (value.length <= 500) {
                        setInfoData({
                          ...infoData,
                          definition: value
                        })
                      }
                    }}
                    placeholder="Enter the Introduction"
                  />
                  <div className="count">
                    {infoData.definition.length}/500
                  </div>
                </div>
              </div>
              <div className="Create-btn">
                <button
                  disabled={
                    infoData.avatar === '' || infoData.name === '' || infoData.definition === ''
                      ? true : false
                  }

                  onClick={() => {
                    stepChangFun(3)
                    localStorage.setItem('infoData', JSON.stringify(infoData));
                    // 保存至本地存储
                    localStorage.setItem('stepOrd', '3');
                  }}
                >Create</button>
              </div>
            </div>
          </div >
          <div className="step3 step">
            <div className="follow">
              <div className="pic">
                <img src={require('../../assets/image/follow-pic.png')} alt="" width={'100%'} />
              </div>
              <h4>Follow us on Twitter</h4>
              <p>One last step to get the reward.</p>
            </div>
            <div className="follow-btn">
              <button
                onClick={handleTwitterAuth}
              >
                {loading ? <LoadingOutlined /> : 'Follow us'}
              </button>
            </div>
          </div>
          <div className="step4 step">
            <div className="Claim">
              <div className="pic">
                <div className="border">
                  <img src={infoData.avatar} alt="" width={'100%'} />
                </div>
              </div>
              <h4>{infoData.name ? infoData.name : 'liniqo1122'}</h4>
              {/* <p>{infoData.definition ? infoData.definition : 'SAVA is a charismatic and captivating internet sensation.SAVA is a charismatic and captivating internet sensation.SAVA is a charismatic and captivating internet sensation.SAVA is a charismatic and captivating internet sensation.SAVA is a charismatic and captivating internet sensation.'}</p> */}
              <div className="pass">
                <p>The pass will include significant value in our later phase:</p>
                <ul>
                  <li>
                    Claim mainnet Soul Pass (omnichain AI social identity)
                  </li>
                  <li>
                    Claim exclusive AI props
                  </li>
                  <li>
                    Claim early participant airdrops
                  </li>
                </ul>


              </div>
            </div>
            <div className="Claim-btn">
              <button
                onClick={() => {
                  if (selectWalletType === 'tp') {
                    mintTPFun()
                  } else {
                    mintOKXFun()
                  }
                }}

              >
                {loading ? <LoadingOutlined /> : 'Claim Early Pass'}

              </button>
            </div>
          </div>
          <div className="step5 step">
            <h1>Earn airdrops in CharacterX App!</h1>
            <div className="adv-box">
              <img src={require('../../assets/image/ads.png')} alt="" width={'100%'} />
            </div>
            <div className="download">
              <a href="https://apps.apple.com/us/app/character-x-roleplay-ai-chat/id6464392665
" >
                <img src={require('../../assets/image/apple-icon.png')} alt="" />
                App Store
              </a>
              <a href="https://play.google.com/store/apps/details?id=xyz.aicentr.gptx" >
                <img src={require('../../assets/image/Google-icon.png')} alt="" />
                Google Play
              </a>
            </div>
          </div>
        </div >
      </div >
      <div className="camear_model">
        <div className="camear_model-bg">
          <div className="camear_model-bg-main"
            onClick={closeCameartModel}
          ></div>
        </div>
        <div className="container">
          <div className="camear_model-content">
            <button onClick={() => {
              fileInputRef.current.click();
            }}>
              <img src={require('../../assets/image/Camera.png')} alt="" />
              Camera
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                capture="environment"
                style={{ display: 'none' }}
                onChange={(e: any) => {
                  handleFileChange(e)
                }}
              />
            </button>
            <button
              onClick={
                () => {
                  fileInputRef2.current.click();
                }
              }
            >
              <img src={require('../../assets/image/Album.png')} alt="" />
              Album
              <input
                ref={fileInputRef2}
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={(e: any) => {
                  console.log('%c🀁 e', 'color: #917399; font-size: 20px;', e);
                  // toast.warn(e)
                  handleFileChange(e)
                }}
              />
            </button>

          </div>
        </div>
      </div>
      <div className="wallet_model">
        <div className="wallet_model-bg">
          <div className="wallet_model-bg-main"
            onClick={closeWalletModel}
          ></div>
        </div>
        <div className="content">
          <div className="close" onClick={closeWalletModel}>
            <img src={require('../../assets/image/Closed.png')} alt="" width={'100%'} />
          </div>
          <h2>Log in with wallet</h2>
          <div className="btn-group">
            <button onClick={TPWalletConnect}>
              <img src={require('../../assets/image/tp-logo.png')} alt="" />
            </button>
            <button
              onClick={
                OkxWalletConnect
              }
            >
              <img src={require('../../assets/image/okx-logo.png')} alt="" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;