import axios from "axios";

const TwitterAuth = () => {
  const handleTwitterAuth = () => {
    // Send a request to your backend to initiate Twitter OAuth process

    axios.get('https://sandbox-api.characterx.ai/gptx/v1/twitter-auth')
      .then((response: any) => {
        console.log(response.data); // 处理返回的数据
        window.location.href = response.data.redirectUrl; // Assuming backend sends the redirect URL
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });

  };

  return (
    <div>
      <h1>Twitter Authorization</h1>
      <button onClick={handleTwitterAuth}>Authorize with Twitter</button>
    </div>
  );
};

export default TwitterAuth;