import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/home';
import TwitterAuth from './pages/twitter';
import Mint from './pages/mint';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/twitter" element={<TwitterAuth />} />
        <Route path="/mint" element={<Mint />} />


      </Routes>

      <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
    </Router>
  );
}

export default App;
